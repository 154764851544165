<template>
  <el-card>
    <CommonForm
      label-position="right"
      label-width="130px"
      :formDataGroups="formDataGroups"
      :modifyData="modifyData"
      :apifunc="apifunc"
    />
  </el-card>
</template>

<script>
import CommonForm from '@/components/CommonForm/index.vue'
import {
  getUserInfo,
  getInstitutionList,
  createUser,
  updateUserInfo
} from '@/services'
import jsonData  from './jsonData'
export default {
  components: {
    CommonForm
  },
  data () {
    return {
      formDataGroups: jsonData.formDataGroups,
      id: '',
      modifyData: null,
      apifunc: createUser
    }
  },
  created () {
    this.id = this.$route.query.id
    this.initData()
    if (this.id) {
      this.apifunc = (data) => updateUserInfo(this.id, data)
      this.initUserInfo()
    }
  },
  methods: {
    async initUserInfo () {
      try {
        const res = await getUserInfo(this.id)
        if (res.data.user) {
          const {user} = res.data
          user.institution_id = user.institution ? user.institution.id : ''
          delete user.institution
          this.modifyData = res.data.user
        }
      } catch (error) {
        
      }
    },
    async initData() {
      try {
        const res = await getInstitutionList()
        if (res.data.institutions && res.data.institutions.length > 0) {
          this.formDataGroups[0].formDataItems[5].options = res.data.institutions
        }
      } catch (error) {
        
      }
    }
  }
}
</script>