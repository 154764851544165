import { FLIC_ROLE_LIST } from '@/utils/constant'
export default {
  formDataGroups: [
    {
      title: '',
      name: 0,
      formDataItems: [
        {
          span: 24,
          formtype: 'switch',
          prop: 'active',
          label: 'Active',
          placeholder: '',
          value: true,
          object: {}
        },
        {
          span: 9,
          formtype: 'input',
          prop: 'name',
          label: 'Name',
          placeholder: 'Please enter the name',
          object: {
            required: true,
            rules: [
              { required: true, message: 'Please enter the name', trigger: 'blur' }
            ]
          }
        },
        {
          span: 9,
          formtype: 'input',
          prop: 'ic',
          label: 'IC',
          placeholder: 'Please enter the IC',
          object: {
            required: true,
            rules: [
              { required: true, message: 'Please enter the IC', trigger: 'blur' }
            ]
          }
        },
        {
          span: 9,
          formtype: 'input',
          prop: 'email',
          label: 'Email',
          placeholder: 'Please enter the email',
          object: {
            required: true,
            rules: [
              { required: true, message: 'Please enter the email', trigger: 'blur' }
            ]
          }
        },
        {
          span: 9,
          formtype: 'input',
          prop: 'phone',
          label: 'Phone Number',
          placeholder: 'Please enter the phone number',
          object: {
            required: true,
            maxlength: 20,
            rules: [
              { required: true, message: 'Please enter the email', trigger: 'blur' },
              { pattern: new RegExp(/^[1-9]\d*$/, 'g'), message: 'Please enter right phone number', trigger: 'blur' }
            ]
          }
        },
        {
          span: 9,
          formtype: 'select',
          prop: 'institution_id',
          label: 'Institution',
          placeholder: 'Please select the institution',
          options: [],
          object: {
            required: true,
            key: 'id',
            rules: [
              { required: true, message: 'Please select the institution', trigger: 'blur' }
            ]
          }
        },
        {
          span: 9,
          formtype: 'select',
          prop: 'role',
          label: 'Role',
          placeholder: 'Please enter the role',
          options: FLIC_ROLE_LIST,
          object: {
            required: true,
            rules: [
              { required: true, message: 'Please enter the Role', trigger: 'blur' }
            ]
          }
        },
        {
          span: 9,
          formtype: 'input',
          prop: 'mcr',
          label: 'MCR',
          placeholder: 'Please enter the MCR',
          object: {
          }
        },
        {
          span: 9,
          formtype: 'input',
          prop: 'snb',
          label: 'SNB',
          placeholder: 'Please enter the SNB',
          object: {
          }
        }
      ]
    }
  ]
}